<template>
  <v-dialog ref="menu" v-model="menu_status" :return-value.sync="actual_color" persistent width="290px">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="colored"
        :label="label"
        :prepend-inner-icon="icon.mdiFormatColorFill"
        readonly
        outlined
        dense
        hint="Select A Color"
        v-bind="attrs"
        @input="$emit('input', $event.hex)"
        @blur="$emit('forceColorUpdate', actual_color)"
        v-on="on"
      />
    </template>
    <v-color-picker
      v-model="color_obj"
      @input="$emit('input', $event.hex)"
      @blur="$emit('forceColorUpdate', color_obj.hex)"
      hide-canvas
      hide-mode-switch
      hide-sliders
      mode="hexa"
      show-swatches
      swatches-max-height="194"
    ></v-color-picker>
    <v-card class="mx-auto" flat>
      <v-card-actions>
        <v-btn text color="primary" style="color: #0b6ff4" @click="menu_status = false">
          Cancel
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn text color="primary" style="color: #0b6ff4" @click="ok()">
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiFormatColorFill } from "@mdi/js";

export default {
  name: "CustomColorPickerDialog",
  props: {
    label: {
      type: String,
      description: "Select Color",
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      menu_status: false,
      actual_color: null,
      color_obj: {},
      icon: {
        mdiFormatColorFill,
      },
    };
  },
  computed: {
    colored() {
      return this.value;
    },
  },
  watch: {
    color_obj() {
      this.actual_color = this.color_obj.hex;
    },
  },
  methods: {
    ok() {
      this.$refs.menu.save(this.actual_color);
      this.$emit("forceColorUpdate", this.actual_color);
    },
  },
};
</script>

<style scoped>
.v-picker--time .v-time-picker-clock__hand.accent {
  background-color: #0b6ff4 !important;
  border-color: #0b6ff4 !important;
}
</style>
