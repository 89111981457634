<template>
  <v-dialog ref="menu" v-model="menu_status" :return-value.sync="actual_time" persistent width="290px">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="dated"
        :label="label"
        :prepend-inner-icon="icon.mdiClockTimeFourOutline"
        readonly
        outlined
        dense
        hint="Select Time Slot"
        v-bind="attrs"
        @input="$emit('input', $event)"
        @blur="$emit('forceTimeUpdate', actual_time)"
        v-on="on"
      />
    </template>
    <v-time-picker
      v-model="actual_time"
      @input="$emit('input', $event)"
      @blur="$emit('forceTimeUpdate', actual_time)"
      :max="end"
      :min="start"
      full-width
      header-color="#0b6ff4"
      format="24hr"
    >
      <v-spacer />
      <v-btn text color="primary" style="color: #0b6ff4" @click="menu_status = false">
        Cancel
      </v-btn>
      <v-btn text color="primary" style="color: #0b6ff4" @click="ok()">
        OK
      </v-btn>
    </v-time-picker>
  </v-dialog>
</template>

<script>
import { mdiClockTimeFourOutline } from "@mdi/js";

export default {
  name: "CustomTimePickerDialog",
  props: {
    label: {
      type: String,
      description: "Input Label",
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    options: {
      type: Object,
      default: function () {
        return {};
      },
    },
    start: {
      type: String,
      default: null,
    },
    end: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      menu_status: false,
      actual_time: null,
      icon: {
        mdiClockTimeFourOutline,
      },
    };
  },
  computed: {
    dated() {
      return this.value;
    },
  },
  methods: {
    ok() {
      this.$refs.menu.save(this.actual_time);
      this.$emit("forceTimeUpdate", this.actual_time);
    },
  },
};
</script>

<style scoped>
.v-picker--time .v-time-picker-clock__hand.accent {
  background-color: #0b6ff4 !important;
  border-color: #0b6ff4 !important;
}
</style>
