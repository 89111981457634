<template>
  <v-card>
    <v-container>
      <validation-observer ref="observer">
        <form class="p-2" @submit.prevent="submit">
          <v-row>
            <v-col cols="12" sm="6">
              <validation-provider v-slot="{ errors }" name="Registration" rules="required">
                <v-text-field
                  v-model="aircraft.registration"
                  :error-messages="errors"
                  label="Registration"
                  required
                  outlined
                  dense
                  :prepend-inner-icon="icons.mdiAccountOutline"
                >
                </v-text-field>
              </validation-provider>
            </v-col>

            <v-col cols="12" sm="6">
              <validation-provider v-slot="{ errors }" name="Serial Number" rules="required">
                <v-text-field
                  v-model="aircraft.serial_number"
                  :error-messages="errors"
                  label="Serial Number"
                  outlined
                  required
                  dense
                  :prepend-inner-icon="icons.mdiCounter"
                >
                </v-text-field>
              </validation-provider>
            </v-col>

            <v-col cols="12" sm="6">
              <validation-provider v-slot="{ errors }" name="Rpas Color" rules="required">
                <custom-color-picker
                  v-model="aircraft.rpas_color"
                  required
                  :label="`Hours At Entry`"
                  :error-messages="errors"
                  @forceColorUpdate="forceColorUpdate"
                ></custom-color-picker>
              </validation-provider>
            </v-col>

            <v-col cols="12" sm="6">
              <validation-provider v-slot="{ errors }" name="RLA Expiry" rules="required">
                <date-picker-dialog
                  v-model="aircraft.rla_expiry"
                  :minimum_date="`1950-01-01`"
                  :error-messages="errors"
                  :label="`RLA Expiry`"
                  @forceDateUpdate="forceDateUpdate"
                ></date-picker-dialog>
              </validation-provider>
            </v-col>

            <v-col cols="12" sm="6">
              <validation-provider v-slot="{ errors }" name="Type" rules="required">
                <v-select
                  v-model="aircraft.type_id"
                  :items="aircraft_types"
                  :error-messages="errors"
                  item-text="name"
                  item-value="id"
                  menu-props="auto"
                  label="Type"
                  outlined
                  required
                  dense
                  :prepend-inner-icon="icons.mdiEvPlugType1"
                ></v-select>
              </validation-provider>
            </v-col>

            <v-col cols="12" sm="6">
              <validation-provider v-slot="{ errors }" name="Hours At Entry" rules="">
                <custom-time-picker
                  v-model="aircraft.hours_at_entry"
                  required
                  :label="`Hours At Entry`"
                  :error-messages="errors"
                  @forceTimeUpdate="forceTimeUpdate"
                ></custom-time-picker>
              </validation-provider>
            </v-col>

            <v-col cols="12" sm="6">
              <validation-provider v-slot="{ errors }" name="Status" rules="required">
                <v-select
                  v-model="aircraft.status"
                  :items="aircraft_status"
                  :error-messages="errors"
                  item-text="name"
                  item-value="name"
                  menu-props="auto"
                  label="Status"
                  outlined
                  required
                  dense
                  :prepend-inner-icon="icons.mdiHeartPulse"
                ></v-select>
              </validation-provider>
            </v-col>
          </v-row>

          <v-btn type="submit" color="primary" class="ma-10 pa-5">
            Save
          </v-btn>
        </form>
      </validation-observer>
    </v-container>
  </v-card>
</template>

<script>
import { required } from "vee-validate/dist/rules";
import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
import {
  mdiAccountOutline,
  mdiCounter,
  mdiFormatColorFill,
  mdiClockTimeEightOutline,
  mdiEvPlugType1,
  mdiHeartPulse,
} from "@mdi/js";
import DatePickerDialog from "@/components/DatePickerDialog.vue";
import util from "@/core/utils/misc";
import Request from "@/core/api/request";
import CustomTimePicker from "@/components/TimePickerDialog.vue";
import CustomColorPicker from "@/components/ColorPickerDialog.vue";

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

export default {
  name: "AircraftAdd",
  components: {
    ValidationProvider,
    ValidationObserver,
    DatePickerDialog,
    CustomTimePicker,
    CustomColorPicker,
  },
  data() {
    return {
      aircraft: {},
      icons: {
        mdiAccountOutline,
        mdiCounter,
        mdiFormatColorFill,
        mdiClockTimeEightOutline,
        mdiEvPlugType1,
        mdiHeartPulse,
      },
      aircraft_types: [],
      aircraft_status: [],
    };
  },
  created() {
    this.dependencies();
    this.dependencieStatus();
  },
  methods: {
    forceDateUpdate(value) {
      this.aircraft.rla_expiry = value;
    },
    forceColorUpdate(value) {
      this.aircraft.rpas_color = value;
    },
    updateBrownse() {
      this.$emit("updateBrownse");
    },

    forceTimeUpdate(value) {
      if (value) {
        this.aircraft.hours_at_entry = value;
      }
    },

    async submit() {
      if (this.$refs.observer.validate()) {
        if (this.$refs.observer.validate()) {
          util.isLoading({ active: true, title: "Wait while we process." });
          await Request.post(`${this.$IYA.URI.AIRCRAFT__ADD}`, this.aircraft).then(response => {
            if (response.data.success) {
              this.$swal(`Done !`, `Action accomplished`, `success`);
              this.updateBrownse();
            } else {
              this.$swal(`Failed !`, `${response.data.error}`, `error`);
            }
          });
          util.isLoading();
        }
      }
    },

    async dependencies() {
      const { data, status } = await Request.get(`${this.$IYA.URI.AIRCRAFT_TYPES__BROWSE}`);
      if (status !== this.$IYA.HTTP.OK) {
        return;
      }
      if (data.data.length < 1) {
        return;
      }
      this.aircraft_types = data.data;
    },

    async dependencieStatus() {
      const { data, status } = await Request.get(`${this.$IYA.URI.AIRCRAFT_STATUSES__BROWSE}`);
      if (status !== this.$IYA.HTTP.OK) {
        return;
      }
      if (data.data.length < 1) {
        return;
      }
      this.aircraft_status = data.data;
    },
  },
};
</script>
